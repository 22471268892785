import { useQuery, useMutation } from '@tanstack/react-query'
import { dltProfilePic, editPersonalDetails, getActivities, getBadges, getCountries, getLanguages, getPurchasedCourses, getQrcode, getStates, getUserProfile, learningReport, purchasedCourseDetails, twofaConfiguration, updateLanguage, updateProfilePic, verifyMail, verifyMailOtp } from '../services/profile/profileService'
import { useAuth } from '../context/AuthContext'

const ProfileApis = {
    GetUserProfile: () => {
        const response = useQuery({
            queryKey: ["get-user-profile"],
            queryFn: getUserProfile,


        })
        return response
    },
    EditPersonalDetails: () => {
        const response = useMutation({
            mutationFn: (data) => editPersonalDetails(data),

        })
        return response
    },
    GetCountries: () => {
        const response = useQuery({
            queryKey: ["get-countries"],
            queryFn: () => getCountries()
        })
        return response
    },
    GetStates: (countryId) => {

        const response = useQuery({
            queryKey: ["get-countries", countryId],
            queryFn: () => getStates(countryId),
            enabled: countryId ? true : false
        })
        return response
    },
    GetLanguages: () => {
        const {isAuthenticated}=useAuth()
        const response = useQuery({
            queryKey: ["get-languages"],
            queryFn: () => getLanguages(),
            enabled:isAuthenticated
            
        })
        return response
    },
    UpdateLanguage: () => {
        const response = useMutation({
            mutationFn: (data) => updateLanguage(data),

        })
        return response
    },
    GetBadges: () => {
        const response = useQuery({
            queryKey: ["get-badges"],
            queryFn: () => getBadges(),
        })
        return response
    },
    GetPurchasedCourses: (length,page) => {
        const response = useQuery({
            queryKey: ["get-purchased-courses",length,page],
            queryFn: () => getPurchasedCourses(length,page),
        })
        return response
    },
    UpdateProfilePic: () => {
        const response = useMutation({
            mutationFn: (data) => updateProfilePic(data),

        })
        return response
    },
    DltProfilePic: () => {
        const response = useMutation({
            mutationFn: (data) => dltProfilePic(data),

        })
        return response
    },
    PurchasedCourseDetails: () => {
        const response = useQuery({
            queryKey: ["purchased-course-details"],
            queryFn: () => purchasedCourseDetails(),
        })
        return response
    },
    LearningReport: () => {
        const response = useQuery({
            queryKey: ["learning-report"],
            queryFn: () => learningReport(),
        })
        return response
    },
    VerifyMail: () => {
        const response = useMutation({
            mutationFn: () => verifyMail(),

        })
        return response
    },
    VerifyMailOtp: () => {
        const response = useMutation({
            mutationFn: (data) => verifyMailOtp(data),

        })
        return response
    },
    TwofaConfig: () => {
        const response = useMutation({
            mutationFn: (data) => twofaConfiguration(data),

        })
        return response
    },
    GetQrcode: (status) => {
        console.log("==status===",status)
        const response = useQuery({
            queryKey: ["get-qr-code"],
            queryFn: () => getQrcode(),
            enabled: status === 1 ? true : false
        })
        return response
    }



}

export default ProfileApis