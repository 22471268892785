import { createSlice } from '@reduxjs/toolkit'

export const languageSlice = createSlice({
    name: 'language',
    initialState: {
        userLang:'en'
    },
    reducers: {
        SetUserLanguage: (state, action) => {
            state.userLang = action.payload
        },
        

    }
})

export const { SetUserLanguage } = languageSlice.actions

export default languageSlice.reducer