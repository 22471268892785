import React from 'react'

function AccountDetails() {
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com" />
                    <label htmlFor="floatingInput">Name</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com" />
                    <label htmlFor="floatingInput">Email</label>
                </div>
            </div>
        </div>
    )
}

export default AccountDetails