import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import courseImg from '../../../assets/images/course1.jpg'
import { Link } from 'react-router-dom';
import CourseApis from '../../../queries/course';
import noDataImg from '../../../assets/images/No data-cuate.png'

function RecommendedCourses({ profileData, t }) {

    const { data: recommendedCourse } = CourseApis.GetCourses(profileData?.recommendedCategory, 3)
    const carouselOptions = {
        loop: recommendedCourse?.data?.data?.courses.length > 1,
        margin: 30,
        dots: true,
        nav: false,
        responsive: {
            0: {
                items: 1,
            },
            900: {
                items: 3,
            },
        },
    };


    return (
        <section className="courses_section bg-light">
            <div className="container">
                <div className="main_heading_txt">
                    <div className="row align-items-center text-center">
                        <div className="col-md-12" data-aos="fade-down">
                            <h2>
                                {t('Recommended')}<br />
                                <strong>{t('Course For You')}</strong>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="courseSlide">
                    {
                        recommendedCourse?.data?.data?.courses.length > 0 ?
                            <OwlCarousel id="courseSlide" className="owl-carousel owl-theme" data-aos="fade-down" {...carouselOptions}>
                                {
                                    recommendedCourse?.data?.data?.courses &&
                                    recommendedCourse?.data?.data?.courses.map((item, index) => (
                                        <div className="item">
                                            <Link to={`/course-details/${item.id}`}>
                                                <div className="course_box">
                                                    <div className="course_box_img">
                                                        <img src={item.image ?? courseImg} alt=""/>
                                                    </div>
                                                    <div className="course_box_cnt">
                                                        <div className="course_box_cnt_first_row">
                                                            <span>{item.modules} {t('Lesson')}</span>
                                                            <span><i className="fa-solid fa-star"></i> {item.rating}</span>
                                                        </div>
                                                        <div className="course_box_cnt_head">
                                                            {item.title}
                                                        </div>
                                                        {
                                                            item.description.length > 100 ?
                                                                <p>
                                                                    {`${item.description.slice(0, 100)}.......`} <span style={{color:'#09aabb'}}>Read more</span>
                                                                </p>
                                                                :
                                                                <p>{item.description}</p>
                                                        }

                                                        <div className="course_box_cnt_footer">
                                                            <span className="rate">
                                                                ${item.price}
                                                            </span>
                                                            <a href="/courses">{t('Learn More')}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }

                            </OwlCarousel>
                            :
                            <center>
                                <img src={noDataImg} className='w-50' />
                                <span>{t('No data found')}</span>
                            </center>
                    }

                    <div className="viewAllBtn">
                        <Link to='/courses'>{t('View All Courses')} <i className="fa-solid fa-arrow-right"></i></Link>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default RecommendedCourses