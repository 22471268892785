import React, { useEffect, useState } from 'react'
import Chart from 'chart.js/auto';
import ProfileApis from '../../../queries/profile';
import profilePic from '../../../assets/images/profilepic.png'
import VerifyMail from './VerifyMail';
import toast from 'react-hot-toast'
import { convertTime } from '../../../utils/convertTime';
import { useTranslation } from 'react-i18next';
function UserDetails({ api, profileData, learningReportData,t }) {
    console.log("======profileData============", profileData)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        sendVerificationMail()
    };
    const [twoFaStatus, setTwoFaStatus] = useState(0)
    const updateProfilePic = ProfileApis.UpdateProfilePic()
    const dltProfilePic = ProfileApis.DltProfilePic()
    const verifyMail = ProfileApis.VerifyMail()
    const twoFaconfig = ProfileApis.TwofaConfig()
    const getQrcode = ProfileApis.GetQrcode(twoFaStatus)
    const sendVerificationMail = async () => {
        const response = await verifyMail.mutateAsync()

        if (response.status) {
            toast.success(response.message)
        }
    }

    useEffect(() => {
        const ctx = document.getElementById("mylearning").getContext('2d');
        const barChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: [t("Sun"), t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat")],
                datasets: learningReportData?.datasets
            },
            options: {
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: t('Days')
                        },
                        grid: {
                            drawOnChartArea: true
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: t('Hours')
                        },
                        grid: {
                            drawOnChartArea: true
                        }
                    }
                }
            }
        });

        return () => {
            barChart.destroy();
        };
    }, [learningReportData,t]);

    useEffect(() => {
        setTwoFaStatus(profileData?.twoFa)
    }, [profileData])

    const profilePicHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const response = await updateProfilePic.mutateAsync(formData)
        if (response.status) {
            await api.refetch()
        }

    }

    const handleDeleteProfilePic = async () => {
        await dltProfilePic.mutateAsync()
        await api.refetch()
    }
    const enable2FA = async (status) => {
        const twofaStatus = status === 0 ? 1 : 0
        const response = await twoFaconfig.mutateAsync({ status: twofaStatus })
        if (response.status) {
            toast.success(`2fa ${twofaStatus === 1 ? "enabled" : "disabled"}`)
            await api.refetch()
            // setTwoFaStatus(profileRes?.data?.data?.twoFa)

        } else {
            toast.error('something went wrong')
        }
    }
    const currentMonthSpent = convertTime(learningReportData?.currentMonthSpent)
    const lastMonthSpent = convertTime(learningReportData?.prevMonthSpent)
    return (
        <div className="my_account_top_section">
            <div className="row justify-content-between">
                <div className="col-md-5">
                    <div className="profile_section">
                        <button className="delete-profile-pic-btn" onClick={handleDeleteProfilePic}>
                            <i className="fa-solid fa-trash" style={{ color: 'white' }}></i>
                        </button>
                        <div className="profile_top_dtl">
                            <div className="profile_image">

                                <label htmlFor="file-input">
                                    <img src={profileData?.user_detail?.profilePhoto ?? profilePic} />
                                </label>
                                <input id="file-input" type="file" style={{ display: 'none' }} onChange={profilePicHandler} />
                            </div>
                            <div className="profile_usr_dtl_cnt">
                                <p>{t('hi')},</p>
                                <h3>{profileData?.username}</h3>
                                {/* <p>Your subscription ends on <span>25 February 2015</span></p> */}
                                <p>{profileData?.email}</p>
                            </div>
                        </div>
                       
                        <div className="profile_btn_row">
                            {/* {profileData?.user_detail.emailVerified == 0 ? <a href="#" className="btn btn-primary">Verify Account</a> : <div className="btn btn-primary">Verified <i className="fa-solid fa-circle-check" style={{ "color": "#63E6BE" }}></i></div>} */}
                            {profileData?.user_detail.emailVerified == 0 && <a href="#" className="btn btn-primary" onClick={handleShow}>{t('verify_accnt')}</a>}
                            <button className="btn btn-secondary"
                                disabled={twoFaconfig?.isPending}
                                onClick={() => enable2FA(profileData?.twoFa)}>{profileData?.twoFa ? 'Disable' : 'Enable'} 2FA
                            </button>
                        </div>
                        {
                            twoFaStatus == 1 && (
                                <div className="profile_btn_row1">
                                    <h6 style={{ marginLeft: '1em' }}>{t('2fa_qrcode')}</h6>
                                    <img src={getQrcode?.data?.data} />
                                </div>

                            )
                        }
                        {
                            profileData?.certificates?.length > 0 &&
                            <div className="profile_btn_row1">
                                <p>{t('Download Certificates')}</p>
                                {
                                    profileData?.certificates.map((item, index) => {
                                        return (
                                            <a href="#" className="btn btn-light">{t('Option')} {index + 1}</a>
                                        )
                                    })
                                }


                            </div>
                        }

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="profile_learinin_report">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-9">
                                <h5>{t('Learning Report')}</h5>
                                <canvas id="mylearning"></canvas>
                            </div>
                            <div className="col-md-3">
                                <span>
                                    <p>{t('This Month')}</p>
                                    <strong>{currentMonthSpent?.hours} hr {currentMonthSpent?.minutes} min {currentMonthSpent?.seconds} sec</strong>
                                </span>
                                <span>
                                    <p>{t('Last Month')}</p>
                                    <strong>{lastMonthSpent?.hours} hr {lastMonthSpent?.minutes} min {lastMonthSpent?.seconds} sec</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <VerifyMail profileApi={api} show={show} handleClose={handleClose} sendVerificationMail={sendVerificationMail} mail={profileData?.email} />
            </div>
        </div>


    )
}

export default UserDetails