import React from 'react'
import playIcon from '../../../assets/images/play-ico.png'
import CourseImg2 from '../../../assets/images/course2.jpg'
import CourseImg1 from '../../../assets/images/course1.jpg'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

function CourseDetails({ profileData,t }) {
    const navigate = useNavigate()
    return (
        <div className="last_next_course_sec">

            <div className="row">
                <div className="col-md-6">
                    <div className="last_next_course_box">
                        <div className="last_next_course_box_img">
                            <img src={CourseImg1} alt="" />
                            <div className="play_ico" onClick={() => navigate(`/my-learnings/${profileData?.currentVideo?.id}`,
                                { state: { taskId: profileData?.currentVideo?.taskId } })}>
                                <img src={playIcon} alt="" />
                            </div>
                        </div>
                        <div className="last_next_course_box_cnt">
                            <p>{t('Current Video')} | {t('Continue')}</p>
                            {/* <h5>{profileData?.currentVideo?.category} | {profileData?.currentVideo?.title}</h5> */}
                            <h5>{profileData?.currentVideo?.title}</h5>
                            <p>{t('Last Viewed')} : {moment(profileData?.currentVideo?.lastViewed).format('DD-MM-YYYY hh:mm:ss')}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="last_next_course_box">
                        <div className="last_next_course_box_img">
                            <img src={CourseImg2} alt="" />
                        </div>
                        <div className="last_next_course_box_cnt">
                            <p>{t('Next Chapter')}</p>
                            <h5>{profileData?.nextModule?.title}</h5>
                            <p>{profileData?.nextModule?.videoCount} {t("Videos")}</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default CourseDetails