import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import ReactStars from "react-rating-stars-component";
import LearningApis from '../../../queries/learning';
function CourseStatusBox({ learningData, courseId,t }) {
    const addRating = LearningApis.Addrating()
    const [rating, setrating] = useState()
    const ratingStars = {
        size: 30,
        count: 5,
        color: "rgb(255, 215, 0)",
        activeColor: "rgb(255, 215, 0)",
        value: rating,
        a11y: true,
        isHalf: false,
        emptyIcon: <i className="far fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
        onChange: async value => {
            setrating(value)
            await addRating.mutateAsync({ courseId, rating:value })
        }
    };
    const chartRef = useRef(null);
    const tooltipRef = useRef(null);
    // Data for the chart
    const dataResponse = {
        datasets: [{
            // data: [learningData?.data?.userCompletedtasksCount, learningData?.data?.userPendingtasksCount],
            data: [learningData?.data?.completedPercentage,Number(100-learningData?.data?.completedPercentage)],
            backgroundColor: ['#2ab96a', '#ccc'],
            hoverBackgroundColor: ['#2ab96a', '#ccc'],
        }],
        labels: ['Completed', 'Pending'],
    }

    // const options = {
    //     plugins: {
    //         tooltip: {
    //             enabled: false,
    //             external: ({ chart, tooltip }) => {
    //                 const tooltipEl = tooltipRef.current;

    //                 if (tooltip.opacity === 0) {
    //                     tooltipEl.style.opacity = 0;
    //                     const tooltipContent = tooltipEl.querySelector('p');
    //                     tooltipContent.textContent = learningData?.data?.completedPercentage
    //                     return;
    //                 }

    //                 tooltipEl.style.opacity = 1;

    //                 const sum = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
    //                 const value = chart.data.datasets[0].data[tooltip.dataPoints[0].dataIndex];
    //                 console.log("======value====",value,sum)
    //                 const percent = ((value / sum) * 100).toFixed(2) + '%';

    //                 const tooltipContent = tooltipEl.querySelector('p');
    //                 tooltipContent.textContent = percent;
    //             },
    //         },
    //         legend: {
    //             display: false,
    //         },
    //     },
    //     cutout: '73%',
    //     animation: {
    //         animateRotate: false,
    //         animateScale: true,
    //     },
    //     responsive: true,
    // };
    const options = {
        plugins: {
            tooltip: {
                enabled: false,
                external: ({ tooltip }) => {
                    const tooltipEl = tooltipRef.current;
    
                    // Hide tooltip if not active
                    if (tooltip.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
    
                    // Show tooltip with `completedPercentage` from the backend
                    tooltipEl.style.opacity = 1;
                    const tooltipContent = tooltipEl.querySelector('p');
                    tooltipContent.textContent = learningData?.data?.completedPercentage + '%';
                },
            },
            legend: {
                display: false,
            },
        },
        cutout: '73%',
        animation: {
            animateRotate: false,
            animateScale: true,
        },
        responsive: true,
    };
    
    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        const myDoughnutChart = new Chart(ctx, {
            type: 'doughnut',
            data: dataResponse,
            options: options,
        });

        return () => myDoughnutChart.destroy(); 
    }, [dataResponse, options]);


    return (
        <div className="course_status_box">
            <div className="row align-items-center">
                <div className="col-md-7">
                    <div className="course_status_box_cnt">
                        <span>
                            <div className="chart-wrapper">
                                <canvas ref={chartRef} id="myChart" width="80" height="80"></canvas>
                                <div id="chartjs-tooltip" ref={tooltipRef}>
                                    <div><p>{learningData?.data?.completedPercentage}%</p></div>
                                </div>
                            </div>
                        </span>
                        <span>
                            {learningData?.data?.totalModulesCount} {t("Sections")} * {learningData?.data?.totalTaskCount} {t("Lectures")} * {learningData?.data?.totalHours?.hour?.hours}h {learningData?.data?.totalHours?.hour?.minutes}m {t("total length")}
                        </span>
                    </div>
                </div>
                <div className="col-md-5 text-end">
                    <div className="d-flex flex-column align-items-end">
                        <span className="mb-1">{t("Rate this course")}:</span>
                        <ReactStars {...ratingStars} value={learningData?.data?.userRating}/>
                    </div>
                    {/* <span>Language: English</span> */}
                </div>
            </div>
        </div>
    );
}

export default CourseStatusBox;
