import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage'],  // Order of preference for detecting language
      // caches: ['localStorage', 'cookie'],
    },
    fallbackLng: 'en',
    debug: true,
    ns: ['learning', 'profile', 'common','purchase','payment','home'],
    defaultNS: 'common',
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    interpolation: {
      escapeValue: false,
    },
  });



export default i18n;