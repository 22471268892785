import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const CheckoutForm = ({ handleFinish, loading }) => {
    console.log("===loading===", loading)
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://example.com/order/123/complete",
            },
            redirect: "if_required",
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
                await handleFinish(result.paymentIntent)
            }

        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button disabled={!stripe || loading} className='stripe-payment-btn'>
                {
                    loading ?
                        <i class="fa-solid fa-circle-notch fa-spin" ></i>
                        : "Submit"
                }

            </button>
        </form>
    )
};

export default CheckoutForm;
