import React from 'react';
import { RouterProvider } from 'react-router-dom';
import AppRoutes from './routes';
import AuthLayout from './layouts/AuthLayout';

function App() {
  const router = AppRoutes();

  return (
  
    <RouterProvider router={router} />
  );
}

export default App;
