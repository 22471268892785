import React from 'react'
import InnerPageHeader from '../../../common/InnerPageHeader'
import CourseVideoSection from './CourseVideoSection'
import CourseStatusBox from './CourseStatusBox'
import CourseOverview from './CourseOverview'
import CourseInstructorDetails from './CourseInstructorDetails'

function SingleView({learningData,learningNotes,refetchLearningData,courseId,t}) {
    return (
        <>
            <InnerPageHeader
                title='My Learnings'
                subtitile='My Account'
                titlePath='my-learnings'
                subTitlePath='my-account'
                t={t}
            />
            <section class="mylearning_contant_section">
           
                <div class="container">
                <h4 style={{textTransform:'capitalize'}}>{learningData?.data?.courseDetails?.title}</h4>
                    <CourseVideoSection learningData={learningData} refetchLearningData={refetchLearningData} t={t}/>
                    <CourseStatusBox learningData={learningData} courseId={courseId}t={t}/>
                </div>
            </section>
            <section class="course_content_sec">
                <div class="container">
                    <div class="row">
                        <CourseOverview learningData={learningData} learningNotes={learningNotes} t={t}/>
                        <CourseInstructorDetails learningData={learningData} t={t}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SingleView