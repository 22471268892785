import React, { useEffect, useState } from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'
import Course1 from '../../assets/images/course1.jpg'
import Course2 from '../../assets/images/course2.jpg'
import Course3 from '../../assets/images/course3.jpg'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import CourseApis from '../../queries/course'
import noDataImg from '../../assets/images/No data-cuate.png'
import { useLocation } from 'react-router-dom'
import CardPagination from '../../common/CardPagination'
import { useTranslation } from 'react-i18next'
function Courses() {
    const { isAuthenticated } = useAuth()
    const images = [Course1, Course2, Course3, Course1]
    const [activeCatgry, setactiveCatgry] = useState('')
    const [currentPage, setcurrentPage] = useState(1)
    const [length, setlength] = useState(10)
    const { data: courses } = CourseApis.GetCourses(activeCatgry,length, currentPage)
    const { state } = useLocation()
    const {t}=useTranslation('home')
    useEffect(() => {
        if (!state) return;
        setactiveCatgry(state.category)
    }, [state])
    useEffect(() => {
        setcurrentPage(1)
    }, [activeCatgry])
    return (
        <>
            <InnerPageHeader
                title="Courses"
                subtitile={isAuthenticated ? 'My Account' : ''}
                titlePath={isAuthenticated ? 'courses' : ''}
                subTitlePath={isAuthenticated ? 'my-account' : ''}
                t={t}
            />
            <section className="home_category_section">
                <div className="container">

                    <div className="main_heading_txt">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12" data-aos="fade-up">
                                <h2>
                                    {t("Find The Right")}<br />
                                    <strong>{t("Online Course")}</strong>
                                </h2>
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="category_selection_home justify-content-center">
                                    {
                                        courses?.data?.data?.categories?.map((items, index) => {
                                            return (
                                                <a className={`${items.id == activeCatgry ? 'active' : ''}`} href="#"
                                                    onClick={() => setactiveCatgry(items.id)}>{items.name}</a>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {
                            courses?.data?.data?.courses.length > 0 ?
                                courses?.data?.data?.courses?.map((item, index) => {
                                    return (
                                        <div className="col-md-3">
                                            <Link to={`/course-details/${item.id}`}>
                                                <div className="course_box">
                                                    <div className="course_box_img"><img src={item.image ?? Course2} alt="" /></div>
                                                    <div className="course_box_cnt">
                                                        <div className="course_box_cnt_first_row">
                                                            <span>{item.modules} {t("Lesson")}</span>
                                                            <span><i className="fa-solid fa-star"></i> {item.rating}</span>
                                                        </div>
                                                        <div className="course_box_cnt_head">
                                                            {item.title}
                                                        </div>
                                                        {
                                                            item.description.length > 100 ?
                                                                <p>
                                                                    {`${item.description.slice(0, 100)}.......`} <span style={{color:'#09aabb'}}>Read more</span>
                                                                </p>
                                                                :
                                                                <p>{item.description}</p>
                                                        }
                                                        <div className="course_box_cnt_footer">
                                                            <span className="rate">
                                                                ${item.price}
                                                            </span>
                                                            <a href="#">{t("Learn More")}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                                :
                                <div className='col-md-12'>
                                    <center>
                                        <img src={noDataImg} className='w-25' />
                                    </center>

                                </div>
                        }
                        {
                            courses?.data?.totalCount > 0 &&
                            <CardPagination
                                length={length}
                                page={currentPage}
                                pageChangehandler={setcurrentPage}
                                totalEntry={courses?.data?.totalCount}
                            />
                        }


                    </div>
                </div>
            </section>
        </>
    )
}

export default Courses