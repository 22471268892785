import React, { useRef } from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'
import UserDetails from '../../components/Student/Account/UserDetails'
import CourseDetails from '../../components/Student/Account/CourseDetails'
import EditProfile from '../../components/Student/Account/EditProfile'
import PurchasedCourses from '../../components/Student/Account/PurchasedCourses'
import RecommendedCourses from '../../components/Student/Account/RecommendedCourses'
import ProfileApis from '../../queries/profile'
import { useTranslation } from 'react-i18next'
function Account() {
  const profileData = ProfileApis.GetUserProfile()
  const learningReportData = ProfileApis.LearningReport()
  const { t } = useTranslation('profile')
  return (
    <>
      <InnerPageHeader
        title="my_accnt"
        subtitile='my_learnings'
        titlePath='my-account'
        subTitlePath='my-learnings'
        t={t}

      />
      <section class="my_account_section" data-aos="fade-right">
        <div class="container">
          <UserDetails
            api={profileData}
            profileData={profileData?.data?.data}
            learningReportData={learningReportData?.data?.data}
            t={t}
          />
          {
            profileData?.data?.data.currentVideo &&
            <CourseDetails profileData={profileData?.data?.data} t={t} />
          }
          {
            profileData.data &&
            <EditProfile
              api={profileData}
              profileData={profileData?.data?.data}
              t={t}
            />
          }

          <PurchasedCourses
            api={profileData}
            profileData={profileData?.data?.data} 
            t={t}/>



        </div>
      </section>
      {
        profileData?.data && <RecommendedCourses profileData={profileData?.data?.data} t={t}/>
      }

    </>
  )
}

export default Account