import React from 'react'
import ChatSidebar from '../../components/Chat/ChatSidebar'
import ChatMainCard from '../../components/Chat/ChatMainCard'
import InnerPageHeader from '../../common/InnerPageHeader'
function Chat() {
  return (
    <>
    <InnerPageHeader
    title="Chat"/>
     <div className="chat-wrapper">
        <ChatSidebar/>
        <ChatMainCard/>
    </div>
    </>
   
  )
}

export default Chat