import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

  const ContactUsschema = () => {
  const { t } = useTranslation();

  return yup.object({
    name: yup.string().required(t('field_required', { field: t('name') })),
    email: yup.string()
      .required(t('field_required', { field: t('email') }))
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t('invalid_email_format')),
    message: yup.string().required(t('field_required', { field: t('message') }))
  });
};
export default ContactUsschema
