import { useQuery, useMutation } from '@tanstack/react-query'
import { getNotification, getUserDetails, readAllnotification } from '../services/common/commonService'
import { useDispatch } from 'react-redux'
import { SetUserLanguage } from '../redux/reducers/slices/languageSlice'
const CommonApis = {
    GetUserData: () => {
        const dispatch = useDispatch()
        const response = useQuery({
            queryKey: ["get-logged-user-details"],
            queryFn: () => getUserDetails(),

        })
        if (response?.data) {
            dispatch(SetUserLanguage(response?.data?.data?.user_detail?.languageDetails?.code ?? 'en'))
        }
        return response
    },
    GetNotifications: (limit, isAuthenticated) => {
        const response = useQuery({
            queryKey: ["get-notifications", limit],
            queryFn: () => getNotification(limit),
            enabled: isAuthenticated

        })
        return response
    },
    ReadAllNotificatio: () => {
        const response = useMutation({
            mutationFn: (data) => readAllnotification(data)
        })
        return response
    },
}

export default CommonApis