import React from 'react'

function CourseRequirement({courseDetails,t}) {
    return (
        <>
            <h5>{t("Course Requirements")}</h5>
            <p>{courseDetails?.data?.course?.requirements??"lorem ipsum"}</p>
        </>
    )
}

export default CourseRequirement