import { useQuery, useMutation } from '@tanstack/react-query'
import { dltKycFile, getKycCategories, getUploadedKycs, uploadKyc } from '../services/kyc/kycService'

const KycApis = {
    GetKycCategories: () => {
        const response = useQuery({
            queryKey: ["kyc-categories"],
            queryFn: () => getKycCategories()

        })
        return response
    },
    UploadKyc: () => {
        const response = useMutation({
            mutationFn: (data) => uploadKyc(data),

        })
        return response
    },
    GetUploadedKycs: (category) => {
        console.log("=====querycategory==", category)
        const response = useQuery({
            queryKey: ["get-uploaded-kycs", category],
            queryFn: () => getUploadedKycs(category),
        })
        return response
    },
    DltKycFile:()=>{
        const response = useMutation({
            mutationFn: (data) => dltKycFile(data),

        })
        return response
    }

}

export default KycApis