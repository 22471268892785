import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
function TableSkeleton({ colCount, rowCount }) {
    return (
        <tbody>
            {Array(rowCount).fill('').map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {Array(colCount).fill('').map((_, colIndex) => (
                        <td key={colIndex}>
                            <Skeleton
                                baseColor='#f5f6fa'
                            />
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
}

export default TableSkeleton;