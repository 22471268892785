import api from "../../api/axios.instance"


export const getLearningVideo = async (courseId) => {
    try {
        const response = await api.get('student/learnings', { params: { courseId } })
        return response
    } catch (error) {
        return error
    }
}
export const getLearningNotes = async (courseId) => {
    try {
        const response = await api.get('student/notes', { params: { courseId } })
        return response
    } catch (error) {
        return error
    }
}
export const getQandA = async (taskId) => {
    try {
        const response = await api.get('common/comment', { params: { taskId } })
        return response
    } catch (error) {
        return error
    }
}
export const getAnswers = async (qstnId, taskId) => {
    try {
        const response = await api.get('common/answers', { params: { parent: qstnId, taskId } })
        return response
    } catch (error) {
        return error
    }
}
export const postComments = async (data) => {
    try {
        const response = await api.post('common/comment', data)
        return response
    } catch (error) {
        return error
    }
}
export const makeLikes = async (data) => {
    try {
        const response = await api.post('common/comment-reaction', data)
        return response
    } catch (error) {
        return error
    }
}
export const dltComment = async (cmntId) => {
    try {
        const response = await api.delete('common/comment', { data: cmntId })
        return response
    } catch (error) {
        return error
    }
}
export const videoProgress = async (data) => {
    try {
        const response = await api.post('student/progress', data)
        return response
    } catch (error) {
        return error
    }
}
export const currentVideo = async (data) => {
    try {
        const response = await api.post('student/set-currently-watching', data)
        return response
    } catch (error) {
        return error
    }
}
export const createCourseHistory = async (data) => {
    try {
        const response = await api.post('student/create-course-history', data)
        return response
    } catch (error) {
        return error
    }
}
export const completeTask = async (data) => {
    try {
        const response = await api.post('student/completed-task', data)
        return response
    } catch (error) {
        return error
    }
}
export const addRating = async (data) => {
    try {
        const response = await api.post('student/rate-course', data)
        return response
    } catch (error) {
        return error
    }
}
export const getQuizDetails = async (moduleId) => {
    try {
        const response = await api.get('common/quiz', { params: { moduleId } })
        return response
    } catch (error) {
        return error
    }
}
export const submitQuiz = async (data) => {
    try {
        const response = await api.post('common/submit-quiz',data)
        return response
    } catch (error) {
        return error
    }
}