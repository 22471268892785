import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        status: false,
        userType: ''
    },
    reducers: {
        SetloginStatus: (state, action) => {
            state.status = action.payload
        },
        SetUserType: (state, action) => {
            state.userType = action.payload
        }


    }
})

export const { SetloginStatus,SetUserType } = loginSlice.actions

export default loginSlice.reducer