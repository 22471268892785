import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { SetUserType, SetloginStatus } from '../redux/reducers/slices/loginSlice';
import AuthApis from '../queries/auth';
import i18n, { changeLanguage } from 'i18next';
import { SetUserLanguage } from '../redux/reducers/slices/languageSlice';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const userLogout = AuthApis.Logout()
    const [userType, setUserType] = useState(null);
    const dispatch = useDispatch()
    useEffect(() => {
        const loginStatus = localStorage.getItem('loginStatus') === 'true';
        const storedUserType = localStorage.getItem('userType');
        setIsAuthenticated(loginStatus);
        setUserType(storedUserType);
    }, []);

    const login = (userType,loginResponse) => {
        console.log("==loginResponse===",loginResponse)
        dispatch(SetloginStatus(true))
        dispatch(SetUserType('student'))
        localStorage.setItem('loginStatus', 'true');
        localStorage.setItem('userType', userType);
        setIsAuthenticated(true);
        setUserType(userType);
        dispatch(SetUserLanguage(loginResponse?.language))
        i18n.changeLanguage(loginResponse?.language);
    };

    const logout = () => {
        // userLogout.mutate()
        localStorage.removeItem('loginStatus');
        localStorage.removeItem('userType');
        setIsAuthenticated(false);
        setUserType(null);
        i18n.changeLanguage('en');

        // Optionally clear the language detection cache if using i18next-browser-languagedetector
        if (i18n.languageDetector) {
            i18n.languageDetector.cache.clear();
        }

        // If you want to remove any cookies or localStorage items used for storing language preference:
        localStorage.removeItem('i18nextLng'); // If you're storing the language code in localStorage
        document.cookie = 'i18nextLng=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'; // To clear the language cookie


    };
    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, userType, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};