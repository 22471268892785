import React, { createContext, useContext, useRef, useState } from 'react';

const SubmitHandlerContext = createContext();

export const SubmitHandlerProvider = ({ children }) => {
    const submitRef = useRef(null);
    const [paymentLoading, setpaymentLoading] = useState(false)
    console.log("SubmitHandlerProvider initialized, submitRef:", submitRef);
    return (
        <SubmitHandlerContext.Provider value={{ submitRef, paymentLoading, setpaymentLoading }}>
            {children}
        </SubmitHandlerContext.Provider>
    );
};

export const useSubmitHandler = () => useContext(SubmitHandlerContext);