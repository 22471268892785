import React from 'react'
import { Icon } from '@iconify/react';

import chat1 from '../../assets/images/chat/1.png'
import chat2 from '../../assets/images/chat/2.png'
import chat3 from '../../assets/images/chat/3.png'
import chat4 from '../../assets/images/chat/4.png'
import chat5 from '../../assets/images/chat/5.png'
function ChatSidebar() {
    return (
        <div className="chat-sidebar card">
            <div className="chat-sidebar-single active top-profile">
                <div className="img">
                    <img src={chat1} alt="image" />
                </div>
                <div className="info">
                    <h6 className="text-md mb-0">Kathryn Murphy</h6>
                    <p className="mb-0">Available</p>
                </div>
                <div className="action">
                    <div className="btn-group">
                        <button
                            type="button"
                            className="text-secondary-light text-xl"
                            data-bs-toggle="dropdown"
                            data-bs-display="static"
                            aria-expanded="false"
                        >
                            <Icon icon="bi:three-dots" />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-lg-end border">
                            <li>
                                <a
                                    href="#"
                                    className="dropdown-item rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                >
                                    <Icon icon="fluent:person-32-regular" />
                                    Profile
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="dropdown-item rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                >
                                    <Icon icon="carbon:settings" />
                                    Settings
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* chat-sidebar-single end */}
            <div className="chat-search">
                <span className="icon">
                    <Icon icon="iconoir:search" />
                </span>
                <input type="text" name="#0" autoComplete="off" placeholder="Search..." />
            </div>
            <div className="chat-all-list">
                <div className="chat-sidebar-single active">
                    <div className="img">
                        <img src={chat2} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">Kathryn Murphy</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
                <div className="chat-sidebar-single active">
                    <div className="img">
                        <img src={chat3} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">James Michael</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
                <div className="chat-sidebar-single">
                    <div className="img">
                        <img src={chat4} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">Russell Lucas</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
                <div className="chat-sidebar-single">
                    <div className="img">
                        <img src={chat5} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">Caleb Bradley</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
                <div className="chat-sidebar-single active">
                    <div className="img">
                        <img src={chat1} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">Bobby Roy</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
                <div className="chat-sidebar-single active">
                    <div className="img">
                        <img src={chat2} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">Vincent Liam</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
                <div className="chat-sidebar-single active">
                    <div className="img">
                        <img src={chat3} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">Randy Mason</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
                <div className="chat-sidebar-single active">
                    <div className="img">
                        <img src={chat4} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">Albert Wayne</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
                <div className="chat-sidebar-single active">
                    <div className="img">
                        <img src={chat5} alt="image" />
                    </div>
                    <div className="info">
                        <h6 className="text-sm mb-1">Elijah Willie</h6>
                        <p className="mb-0 text-xs">hey! there i'm...</p>
                    </div>
                    <div className="action text-end">
                        <p className="mb-0 text-neutral-400 text-xs lh-1">12:30 PM</p>
                        <span className="w-16-px h-16-px text-xs rounded-circle bg-warning-main text-white d-inline-flex align-items-center justify-content-center">
                            8
                        </span>
                    </div>
                </div>
                {/* chat-sidebar-single end */}
            </div>
        </div>

    )
}

export default ChatSidebar