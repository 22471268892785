import React from 'react'

function QuickCount({count,t}) {
    return (
        <section className="QuickCountSec">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-6" data-aos="fade-down">
                        <span>{("Category")}</span>
                        <strong>{count?.category}+</strong>
                    </div>
                    <div className="col-md-3 col-6" data-aos="fade-down">
                        <span>{t("Course")}</span>
                        <strong>{count?.course}+</strong>
                    </div>
                    <div className="col-md-3 col-6" data-aos="fade-down">
                        <span>{t("Instructor")}</span>
                        <strong>{count?.tutor}+</strong>
                    </div>
                    <div className="col-md-3 col-6" data-aos="fade-down">
                        <span>{t("Students")}</span>
                        <strong>{count?.students}+</strong>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default QuickCount