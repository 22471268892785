import React,{useState,useEffect} from 'react'

function StickyHeader(ref) {
    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (ref.current) {
                setSticky(window.scrollY >= ref.current.offsetTop + 100);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ref]);

    return isSticky;
}

export default StickyHeader