import React, { useState, useRef, useEffect } from 'react'
import { Modal, Col, Row, Button, Form } from 'react-bootstrap'
import { useTimer } from 'react-timer-hook';
import timer from '../../../assets/images/rb_14276.png'
import LearningApis from '../../../queries/learning';
import { Doughnut } from 'react-chartjs-2';
import Aos from 'aos';

function QuizModal({ show, closeModal, QuizDetails, moduleId ,t}) {
    const submitQuiz = LearningApis.SubmitQuiz()
    const timesUpRef = useRef()
    const quizContentRef = useRef()
    const [page, setpage] = useState(0)
    const [timeExpired, settimeExpired] = useState(false)
    const [answers, setAnswers] = useState([])
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [showRslt, setshowRslt] = useState(false)
    const [result, setResult] = useState({})
    const [animateDirection, setAnimateDirection] = useState("fade-right");
    const [startTime, setstartTime] = useState(new Date())
    const [submitTime, setsubmitTime] = useState(null)
    const [timeTaken, setTimeTaken] = useState(0);

    const questionId = QuizDetails?.data?.data[page]?.id;

    const nextHandler = () => {
        setpage((prevPage) => prevPage + 1);
        setAnimateDirection("fade-right")
        resetAnimation()
    };

    const previousHandler = () => {
        setpage((prevPage) => prevPage - 1);
        setAnimateDirection("fade-left")
        resetAnimation()
    };
    const resetAnimation = () => {
        quizContentRef.current.classList.remove(animateDirection);
        void quizContentRef.current.offsetWidth;
        quizContentRef.current.classList.add(animateDirection);
    };

    useEffect(() => {
        if (questionId && answers[questionId]) {
            setSelectedAnswer(answers[questionId][0]);
        } else {
            setSelectedAnswer(null);
        }
    }, [page, questionId, answers]);
    const totalDuration = QuizDetails?.data?.duration || 0;
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + totalDuration);
    const {
        seconds,
        minutes,
        hours,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp,
        onExpire: () => handleTimeExpire(),

    });


    const handleTimeExpire = () => {
        settimeExpired(true)
        if (quizContentRef.current) {
            quizContentRef.current.style.display = 'none';
        }
        if (timesUpRef.current) {
            timesUpRef.current.style.display = 'block'
        }
        setsubmitTime(expiryTimestamp)

    }
    const handleRetry = () => {
        if (quizContentRef.current) {
            quizContentRef.current.style.display = 'block';
        }
        if (timesUpRef.current) {
            timesUpRef.current.style.display = 'none';
        }
        const newExpiryTimestamp = new Date();
        newExpiryTimestamp.setSeconds(newExpiryTimestamp.getSeconds() + QuizDetails?.data?.duration);
        restart(newExpiryTimestamp);
        settimeExpired(false)
    };

    const answerHandler = (e) => {
        const selectedId = e.target.id;
        setSelectedAnswer(selectedId);
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: [selectedId],
        }));
    };
    const submitHandler = async () => {
        const data = {
            moduleId,
            quizId: QuizDetails?.data?.quizId,
            answers: answers
        }
 
        const response = await submitQuiz.mutateAsync(data)
        if (response.status) {
            const now = new Date();
            setsubmitTime(now);
            const timeDifference = Math.floor((now - startTime) / 1000);
            setTimeTaken(timeDifference);
            setResult(response?.data)
            pause()
            setshowRslt(true)

        }
    }

    // Define the data and configuration for the Doughnut chart
    const data = {
        labels: [t('Score'), t('Remaining')],
        datasets: [
            {
                label: 'Quiz Score',
                data: [result?.percentage, 100 - result?.percentage], // Score and remaining percentage
                backgroundColor: [
                    result?.percentage < 50 ? '#FF6347' : '#218daf', // Red if score < 50%, blue otherwise
                    '#e0e0e0', // Light grey for remaining portion
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        cutout: '80%', // Controls the thickness of the doughnut
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false, // Hide the default legend
            },
            tooltip: {
                enabled: false, // Disable the tooltip
            },
        },
    };
    const formatDate = (date) => {
        if (!date) return 'N/A';

        // Get the local date components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Format the date as YYYY-MM-DD HH:MM:SS
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    const handleCloseModal=()=>{
        // if()
    }
    return (
        <Modal
            show={show}
            onHide={handleCloseModal}
            centered
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <div className="modal-content-custom">
                <div className="text-end m-1">
                    <Button variant="secondary" onClick={closeModal}>
                        <i className="fa-solid fa-xmark"></i>
                    </Button>
                </div>

                {/* Modal Body */}
                <Modal.Body id="qusion_modal-body" hidden={showRslt}>
                    <Row>
                        <Col md={6} className="text-lg-start text-center">
                            <div className="module_part font-15">{QuizDetails?.data?.moduleName} Practice Test 1</div>
                        </Col>
                        <Col md={6} className="text-lg-end text-center my-md-0 my-3">
                            <div className="qustion_timer font-15">
                                <span id="timer"> {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span> MINS - TIME TAKEN{' '}
                                <i className="fa-solid fa-stopwatch-20"></i>
                            </div>
                        </Col>
                    </Row>

                    <div className="text-center">
                        <div className="qustion_number_identifier">
                            {t("TRIAL")}{' '}
                            <span className="current_qustion_number" id="qusion_number_change">
                                {page + 1}
                            </span>{' '}
                            {t("OF")} <span className="Total_qustion">{QuizDetails?.data?.totalQuestions}</span>
                        </div>
                    </div>

                    {/* Quiz Content */}
                    <div className={`quiz-content ${animateDirection}`} ref={quizContentRef}>
                        <p class="qusion text-center">{QuizDetails?.data?.data[page]?.question}</p>

                        <div className="options_parent">

                            <Form>
                                <div className="row quiz-row">
                                    {
                                        QuizDetails?.data?.data[page]?.options &&
                                        QuizDetails?.data?.data[page]?.options.map((item, index) => {
                                            const optionLetter = String.fromCharCode(65 + index);
                                            return (
                                                <div className="col-lg-6" data-aos="fade-right">
                                                    <label htmlFor={item.id}>
                                                        <input
                                                            type="radio"
                                                            className="d-none option-check"
                                                            name="options"
                                                            id={item.id}
                                                            onChange={answerHandler}
                                                            value={item.id}
                                                            checked={item.id == selectedAnswer}
                                                            required
                                                        />
                                                        <div className="option">
                                                            <span className="option_identifier">{optionLetter}</span> {item.option}.
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }

                                    {/* <div className="col-lg-6">
                                        <label htmlFor="option-b">
                                            <input type="radio" className="d-none option-check" name="options" id="option-b" />
                                            <div className="option">
                                                <span className="option_identifier">B</span> Assumenda rerum facere alias.
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="option-c">
                                            <input type="radio" className="d-none option-check" name="options" id="option-c" />
                                            <div className="option">
                                                <span className="option_identifier">C</span> Assumenda rerum facere alias.
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-lg-6">
                                        <label htmlFor="option-d">
                                            <input type="radio" className="d-none option-check" name="options" id="option-d" />
                                            <div className="option">
                                                <span className="option_identifier">D</span> Assumenda rerum facere alias.
                                            </div>
                                        </label>
                                    </div> */}
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='times-up text-center mt-3' ref={timesUpRef} style={{ display: 'none' }}>
                        <h2>{t("Time's Up!")}</h2>


                        <div data-aos="fade-right"><img src={timer} className='w-25' /></div>

                        <p>{t("Your quiz time has expired")}.</p>
                        <Button variant="primary" id="btn_submit" onClick={handleRetry}>
                            {t("Retry")}{" "}<i class="fa-solid fa-rotate-right"></i>

                        </Button>
                    </div>
                    {/* Additional Quiz Content */}
                    {/* Repeat content blocks as needed for additional questions */}

                    <div className={`d-flex ${page === 0 ? 'justify-content-end' : 'justify-content-between'}`}>
                        <Button variant="secondary" id="btn_previous" hidden={page === 0 || timeExpired} onClick={previousHandler}>
                            <i className="fa-solid fa-angle-left"></i> {t("Previous")}
                        </Button>
                        {
                            page == QuizDetails?.data?.totalQuestions - 1 ?
                                <Button variant="primary" id="btn_submit" onClick={submitHandler} disabled={submitQuiz?.isPending} hidden={timeExpired}>
                                    {submitQuiz?.isPending ? 'Submitting...' : 'Submit'}  <i className="fa-solid fa-angle-right"></i>
                                </Button>
                                :
                                <Button variant="primary" id="btn_submit" onClick={nextHandler} hidden={page == QuizDetails?.data?.totalQuestions - 1 || timeExpired}>
                                    {t("Next")} <i className="fa-solid fa-angle-right"></i>
                                </Button>

                        }

                    </div>

                </Modal.Body>

                {/* Score Modal Body */}
                {
                    showRslt &&
                    <Modal.Body id="score_modal-body" className="">
                        <div className="text-center score_board_title text-capitalize">{QuizDetails?.data?.moduleName} Practice Test 1</div>
                        <div className="test-result_container">
                            <h6 className="Candidate_name text-center">
                                {t("Candidate")} : <span>{result?.username}</span>
                            </h6>
                            <div className="start_end_time-shown text-center">
                                {t("Start Time")} <span>{formatDate(startTime)}</span> | {t("Submit Time")}{' '}
                                <span>{formatDate(submitTime)}</span>
                            </div>
                            <div className="total_score_shown mt-3">
                                <div>
                                    <h6 className="total_score_num">{result?.totalPoints}</h6>
                                    <p className="total_score_txt m-0">{t("Total Score")}</p>
                                </div>
                                <div className="score_in_chart" >
                                    <div className='doughnut'>
                                        <Doughnut data={data} options={options} />
                                        <div className="inner" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                                            <h2 className="doughnut_score_num" style={{ margin: 0 }}>{result?.pointsEarned}</h2>
                                            <p className="m-0">{t("Candidate's Score")}</p>
                                        </div>
                                    </div>



                                </div>
                                <div>
                                    <h6 class="total_score_num">{Math.floor(timeTaken / 60)}<span class="time-txt">Min</span></h6>
                                    <p class="total_score_txt m-0">{t("Time Takes")}</p>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                }

            </div>
        </Modal>
    )
}

export default QuizModal