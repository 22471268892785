import api from "../../api/axios.instance"

export const getPaymentGateways = async () => {
    try {
        const response = await api.get('common/payment-methods')
        return response
    } catch (error) {
        return error
    }
}
export const createStripeIntent=async(data)=>{
    try {
        const response = await api.post('payment/stripe-payment-intent',data)
        return response
    } catch (error) {
        return error
    }
}
