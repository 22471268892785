import React from 'react'
import { Icon } from '@iconify/react';

function ChatMainCard() {
    return (
        <div className="chat-main card">
            <div className="chat-sidebar-single active">
                <div className="img">
                    <img src="assets/images/chat/11.png" alt="image" />
                </div>
                <div className="info">
                    <h6 className="text-md mb-0">Kathryn Murphy</h6>
                    <p className="mb-0">Available</p>
                </div>
                <div className="action d-inline-flex align-items-center gap-3">
                    <div className="btn-group">
                        <button
                            type="button"
                            className="text-primary-light text-xl"
                            data-bs-toggle="dropdown"
                            data-bs-display="static"
                            aria-expanded="false"
                        >
                            <Icon icon="tabler:dots-vertical" />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-lg-end border">
                            <li>
                                <button
                                    className="dropdown-item rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                    type="button"
                                >
                                    <Icon icon="mdi:clear-circle-outline" />
                                    Clear All
                                </button>
                            </li>
                            <li>
                                <button
                                    className="dropdown-item rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                    type="button"
                                >
                                    <Icon icon="ic:baseline-block" />
                                    Block
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* chat-sidebar-single end */}
            <div className="chat-message-list">
                <div className="chat-single-message left">
                    <img
                        src="assets/images/chat/11.png"
                        alt="image"
                        className="avatar-lg object-fit-cover rounded-circle"
                    />
                    <div className="chat-message-content">
                        <p className="mb-3">
                            It is a long established fact that a reader will be distracted by the
                            readable content of a page when looking at its layout. The point of
                            using Lorem Ipsum is that it has a more-or-less normal distribution of
                            letters.
                        </p>
                        <p className="chat-time mb-0">
                            <span>6.30 pm</span>
                        </p>
                    </div>
                </div>
                {/* chat-single-message end */}
                <div className="chat-single-message right">
                    <div className="chat-message-content">
                        <p className="mb-3">
                            It is a long established fact that a reader will be distracted by the
                            readable content of a page when looking at its layout. The point of
                            using Lorem Ipsum is that it has a more-or-less normal distribution of
                            letters.
                        </p>
                        <p className="chat-time mb-0">
                            <span>6.30 pm</span>
                        </p>
                    </div>
                </div>
                {/* chat-single-message end */}
                <div className="chat-single-message left">
                    <img
                        src="assets/images/chat/11.png"
                        alt="image"
                        className="avatar-lg object-fit-cover rounded-circle"
                    />
                    <div className="chat-message-content">
                        <p className="mb-3">
                            The point of using Lorem Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using 'Content here, content
                            here', making it look like readable English. Many desktop publishing
                            packages and web page editors now use Lorem Ipsum as their
                            default.Contrary to popular belief, Lorem Ipsum is not simply random
                            text is the model text for your company.
                        </p>
                        <p className="chat-time mb-0">
                            <span>6.30 pm</span>
                        </p>
                    </div>
                </div>
                {/* chat-single-message end */}
            </div>
            <form className="chat-message-box">
                <input type="text" name="chatMessage" placeholder="Write message" />
                <div className="chat-message-box-action">
                    <button type="button" className="text-xl">
                        <Icon icon="ph:link" />
                    </button>
                    <button type="button" className="text-xl">
                        <Icon icon="solar:gallery-linear" />
                    </button>
                    <button
                        type="submit"
                        className="btn btn-sm btn-primary-600 radius-8 d-inline-flex align-items-center gap-1"
                    >
                        Send
                        <Icon icon="f7:paperplane"/>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ChatMainCard