import React from 'react'
import ProfileApis from '../../../queries/profile'
import noDataImg from '../../../assets/images/No data-cuate.png'
function Badges({t}) {
    const { data: badges } = ProfileApis.GetBadges()
    return (
        <div className="row badges-container">
            {
                badges?.data &&
                    badges?.data?.data?.length > 0 ?
                    badges?.data?.data?.map((items, index) => (
                        <div className="col-md-3">
                            <img src={items?.badge?.image} alt='no image' />
                            <span>{items?.badge?.name}</span>
                        </div>
                    ))
                    :
                    <div className='col-md-12'>
                        <img src={noDataImg} className='w-50'/>
                        <span>{t('No data found')}</span>
                    </div>
            }



        </div>
    )
}

export default Badges