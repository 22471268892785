
import * as yup from "yup"
import { useTranslation } from 'react-i18next';

const ProfileDetailsSchema = () => {
  const { t } = useTranslation();

  return yup
    .object({
      firstName: yup.string().required(t('field_required', { field: t('firstName') })),
      lastName: yup.string().optional(),
      mobile: yup.string().max(10, t("can't exceed mobile number length 10")).required(t('field_required', { field: t('mobile') })).matches(/^\d+$/, "Invalid phone number"),
      countryId: yup.string().optional().nullable(),
      stateId: yup.string().optional().nullable()
    })
    .required()
}
export default ProfileDetailsSchema