import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../../components/Payments/stripe/CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


export default function Stripe({clientSecret,handleFinish,publicKey,loading}) {
    const stripePromise = loadStripe(publicKey);
    const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm handleFinish={handleFinish} loading={loading}/>
        </Elements>
    );
};