import { createStripeIntent, getPaymentGateways } from "../services/payment/paymentService"
import { useQuery, useMutation } from '@tanstack/react-query'
const PaymentApis = {
    GetpaymentGateways: () => {
        const response=useQuery({
            queryKey: ["get-payment-gateways"],
            queryFn: () => getPaymentGateways()
        })
        return response
    },
    CreateStripeIntent: () => {
        const response = useMutation({
            mutationFn: (data) => createStripeIntent(data)
        })
        return response
    }
}
export default PaymentApis;