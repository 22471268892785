import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loginvector from '../../assets/images/login-vector.png';
import { useDispatch } from 'react-redux'
import { SetUserType, SetloginStatus } from '../../redux/reducers/slices/loginSlice';
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loginschema from '../../schema/login';
import toast from 'react-hot-toast'
import AuthApis from '../../queries/auth';
import { Icon } from '@iconify/react'
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
const Login = () => {
    const {t}=useTranslation()
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(Loginschema),
    })
    const { login } = useAuth()
    const navigate = useNavigate()
    const userLogin = AuthApis.LoginUser()
    const loginUserByGoogle = AuthApis.GoogleSignup()
    const [eyeOPen, seteyeOPen] = useState(false)
    const [twoFa, settwoFa] = useState(false)

    const loginHandler = async (data) => {
        const isValid = await trigger()
        if (!isValid) {
            return toast.error("error....")
        }
        data.role = ["student"]
        const response = await userLogin.mutateAsync(data)
        if (response.status) {

            if (response?.data?.twoFa) {
                settwoFa(true)
                navigate('/two-fa-authentication', { state: { loginCredentials: data } })
            } else {
                login('student',response?.data)
                navigate('/my-account')
            }

        } else {
            setError(response?.error?.field, { message: t(response?.error?.message) })
        }

    }

    const googleSignin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const response = await loginUserByGoogle.mutateAsync({ token: tokenResponse?.access_token, role: 'student' })
            if (response.status) {
                login('student')
            }
        },
        onError: (error) => {
            toast.error('Something went wrong')
        }
    });

    return (
        <section className="login_section" data-aos="fade-right">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="login_box">
                            <div className="login_box_left_sc">
                                <h3>
                                    <span>Welcome Back</span><br />
                                    Login Now
                                </h3>

                                <form onSubmit={handleSubmit(loginHandler)} className='reset-pswd-form'>
                                    <div className="form-floating mb-3">
                                        <input type="email"  {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="floatingInputEmail" placeholder="name@example.com" />
                                        <label htmlFor="floatingInputEmail">Email address</label>
                                        <div class="invalid-feedback">
                                            {errors?.email?.message}
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type={eyeOPen?'text':'password'} {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="floatingPassword" placeholder="Password" />
                                        <label htmlFor="floatingPassword">Password</label>
                                        <div class="invalid-feedback">
                                            {errors?.password?.message}
                                        </div>
                                        <span className='pswd-eye' onClick={() => seteyeOPen(!eyeOPen)}>
                                            {
                                                eyeOPen ?
                                                    <Icon icon="oi:eye" width="20" height="20" />
                                                    : <Icon icon="ooui:eye-closed" width="20" height="20" />

                                            }

                                        </span>
                                    </div>
                                    <Link to={'/forgot-password'} className="login_box_left_forgot_txt">Forgot Password?</Link>

                                    <button className="btn btn-primary" type="submit">Login</button>
                                </form>
                                <div className="login_with_sec">
                                    <div className="login_with_head"><span>or Login with</span></div>
                                    <div className="login_with_sec_ico_row">
                                        <a href="#" className="login_with_sec_ico"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a href="#" className="login_with_sec_ico" onClick={() => googleSignin()}><i className="fa-brands fa-google"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="login_box_right_sec text-center">
                                <h3>
                                    Don’t Have an <br />
                                    Account?
                                </h3>
                                <Link to='/register' className="btn btn-primary px-4">Register</Link>
                                <div className="loginRegisterImage">
                                    <img className="img-fluid" src={Loginvector} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
