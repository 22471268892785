import api from "../../api/axios.instance"

export const getUserDetails = async () => {
    try {
        const response = await api.get('common/profile-photo')
        return response
    } catch (error) {
        return error
    }
}
export const getNotification = async (limit) => {
    try {
        const response = await api.get('common/notifications', { params: { limit } })
        return response
    } catch (error) {
        return error
    }
}

export const readAllnotification=async(data)=>{
    try {
        const response = await api.post('common/notifications',data)
        return response
    } catch (error) {
        return error
    }
}
