// src/CustomToaster.js
import React from 'react';
import { Toaster } from 'react-hot-toast';

const CustomToaster = () => {
    return (
        <Toaster
            position="bottom-center"
            toastOptions={{
                // Global options
                // duration:Infinity,
                style: {
                    background: '#363636',
                    color: '#fff',
                    borderRadius: '8px',
                    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    padding:'10px 15px 10px',
                    fontSize:'18px',
                    fontWeight:'bold',
                    textTransform:'capitalize'
                },
                // Default options for specific types
                success: {
                    // duration:3000,
                    style: {
                        // background: 'linear-gradient(to right, rgb(33, 116, 160), rgb(50, 177, 217))',
                        background:'linear-gradient(to right, rgb(15, 108, 38), rgb(116, 196, 121))'
                    },
                    iconTheme: {
                        primary: '#fff',
                        secondary: '#20e12c',
                    },
                },
                error: {
                    duration:5000,
                    style: {
                        background: 'linear-gradient(to right, rgb(193, 43, 16), rgba(231, 56, 39, 0.9))',
                    },
                    iconTheme: {
                        primary: '#fff',
                        secondary: '#c63e26',
                    },
                },
            }}
        />
    );
};

export default CustomToaster;
