import React, { useRef, useState } from 'react'
import twofaImg from '../../assets/images/Computer login-rafiki.png'
import AuthApis from '../../queries/auth';
import { useLocation,useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext';
function TwofaOtp() {
    const inputRefs = useRef([]);
    const { state: loginData } = useLocation()
    const [otpErr, setotpErr] = useState('')
    const navigate=useNavigate()
    const { login } = useAuth()

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            if (index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        } else {
            e.target.value = '';
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !e.target.value && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };
    const twoFaLogin = AuthApis.TwofaVerification()
    const verifyOtp = async (e) => {
        e.preventDefault()
        const otp = inputRefs.current.map(input => input.value).join('');
        const data = {
            email: loginData?.loginCredentials?.email,
            password: loginData?.loginCredentials?.password,
            role: loginData?.loginCredentials?.role,
            otp: otp
        }
        const response = await twoFaLogin.mutateAsync(data)
        if (response.status) {
            login()
            navigate('/my-account')
        } else {
            if (response.code === 999) {
                setotpErr('Invalid otp')
            } else {
                setotpErr(response?.error?.message)
            }
        }
    }
    return (
        <section className="login_section" data-aos="fade-right">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="login_box">
                            <div className="login_box_left_sc">
                                <h3>
                                    2FA Authentication
                                    <span>Enter otp</span><br />

                                </h3>

                                <form className='reset-pswd-form' onSubmit={verifyOtp}>
                                    <div className="otp-input">
                                        {Array(6).fill('').map((_, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                maxLength="1"
                                                required
                                                onChange={(e) => handleChange(e, index)}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                ref={(el) => inputRefs.current[index] = el}
                                                style={{ width: '50px', textAlign: 'center', marginRight: '10px' }}
                                            />
                                        ))}
                                    </div>


                                    <button className="btn btn-primary" type="submit">Verify</button>
                                </form>

                            </div>
                            <div className="login_box_right_sec text-center">

                                {/* <Link to='/register' className="btn btn-primary px-4">Register</Link> */}
                                <div className="loginRegisterImage">
                                    <img className="img-fluid" src={twofaImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TwofaOtp