import React from 'react'
import forgotPswd from '../../assets/images/Forgot password-bro.png'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import AuthApis from '../../queries/auth'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

function Forgotpassword() {
    const { register, handleSubmit, formState: { errors }, setError, reset } = useForm()
    const forgotPswrd = AuthApis.ForgotPassword()
    const navigate = useNavigate()
    const submitHandler = async (data) => {
        const response = await forgotPswrd.mutateAsync(data)
        if (response.status) {
            toast.success('Check your email for further procedures')
            reset()
            navigate('/login')

        } else {
            if (response.code === 4021) {
                setError('email', { message: 'Invalid email' })
            } else {
                toast.error('something went wrong')
            }

        }
    }
    return (
        <section className="login_section" data-aos="fade-right">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="login_box">
                            <div className="login_box_left_sc">
                                <h3>
                                    {/* <span>Forgot password,</span><br /> */}
                                    Forgot Password
                                </h3>
                                <form onSubmit={handleSubmit(submitHandler)}>
                                    <div className="form-floating mb-3">
                                        <input type="text"  {...register("email", {
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message: "Please enter a valid email address"
                                            }
                                        })}
                                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            id="floatingInputEmail" placeholder="name@example.com" />
                                        <label htmlFor="floatingInputEmail">Enter email address</label>
                                        <div class="invalid-feedback">
                                            {errors?.email?.message}
                                        </div>
                                    </div>


                                    <button className="btn btn-primary" type="submit" disabled={forgotPswrd.isPending ? true : false}>Submit</button>
                                </form>

                            </div>
                            <div className="login_box_right_sec text-center">
                                <h3>
                                    Are you forgot <br />
                                    your password?
                                </h3>
                                <div className="loginRegisterImage p-4">
                                    <img className="img-fluid" src={forgotPswd} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Forgotpassword