import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import InnerPageHeader from '../../../common/InnerPageHeader'
import { Card } from 'react-bootstrap'
import Logo from '../../../assets/images/logo.png'
import { useParams } from 'react-router-dom'
import CourseApis from '../../../queries/course'
import moment from 'moment'
import TableSkeleton from '../../../utils/skeletons/TableSkeleton'
import { Icon } from '@iconify/react'
import { useReactToPrint } from 'react-to-print';

function PurchaseInvoice() {
    const { id } = useParams()
    const { data: InvoiceData, isLoading } = CourseApis.GetInvoiceDetails(id)
   console.log("=====InvoiceData====",InvoiceData)
    const print = useRef();
    const handlePrint = useReactToPrint({
        content: () => print?.current,
    });
    return (
        <>
            <InnerPageHeader
                title="Invoice"
                subtitile="My Account"
                titlePath="invoice"
                subTitlePath="my-account"
            />
            <section className="my_account_section" ref={print}>
                <div className="container">
                    <div className="my_purchase_invoice_section" >
                        <span style={{ float: 'right',cursor:'pointer' }} className='mb-3 no-print_icon' onClick={handlePrint}>
                            <Icon icon="material-symbols:print" width="24" height="24" />
                        </span>
                        <div className="invoice-top-section">

                            <div className="row">

                                <div className="col-lg-6">
                                    <img src={Logo} alt="Logo" />
                                </div>
                                <div className="col-lg-6">
                                    <div className="invoice-right-side-text">
                                        <h4 style={{ color: '#32b1d9' }}>Invoice</h4>
                                        {/* <button>print <Icon icon="lets-icons:print" width="24" height="24" /></button> */}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="customer-details-container mt-4">
                                        <span>
                                            <strong>Customer Details</strong>
                                        </span>

                                        <div className="customer-details mt-2" style={{ textTransform: 'capitalize' }}>
                                            <p>

                                                {InvoiceData?.data?.customer?.fullName}
                                            </p>
                                            {
                                                InvoiceData?.data?.customer?.country &&
                                                <p>

                                                    {InvoiceData?.data?.customer?.country}, {InvoiceData?.data?.customer?.state}
                                                </p>
                                            }

                                            {/* <p>New York NY</p> */}
                                            <p>

                                                {InvoiceData?.data?.customer?.mobile}
                                            </p>
                                        </div>


                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="invoice-details mt-4">
                                        <span>

                                            <strong>ORDER #{InvoiceData?.data?.invoice?.id}</strong>
                                            <br />
                                            {moment(InvoiceData?.data?.invoice?.date).format('DD MMM YYYY')}

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="order-details">
                            <div className="table-responsive">
                                <table className="table table-style mb-0 p-0 mt-5">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th style={{ 'width': '40%' }}>Item</th>
                                            <th>Quantity</th>
                                            <th style={{ 'textAlign': 'right' }}>Sub Total</th>
                                        </tr>
                                    </thead>
                                    {
                                        isLoading ?
                                            <TableSkeleton rowCount={4} colCount={4} />
                                            :
                                            <tbody>
                                                {
                                                    InvoiceData?.data?.products?.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item.item}</td>
                                                                <td>1</td>
                                                                <td style={{ 'textAlign': 'right' }}>${item?.price}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }


                                            </tbody>
                                    }

                                    <tfoot>

                                        <tr>

                                            <td style={{ 'textAlign': 'right' }} colSpan={4}>
                                                <table style={{ 'width': '400px', 'float': 'right' }}>
                                                    <tr>
                                                        <td colSpan={2} className="border-0"></td>
                                                        <td className="border-0 p-1">
                                                            <strong>Subtotal</strong>
                                                        </td>
                                                        <td className="border-0 p-1">
                                                            ${InvoiceData?.data?.pricingDetails?.subTotal}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colSpan={2} className="border-0"></td>
                                                        <td className="border-0 p-1">
                                                            <strong>TAX</strong>
                                                        </td>
                                                        <td className="border-0 p-1">
                                                            ${InvoiceData?.data?.pricingDetails?.tax}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} className="border-0"></td>
                                                        <td className="border-0 p-1">
                                                            <strong>Grand Total (Incl.Tax)</strong>
                                                        </td>
                                                        <td className="border-0 p-1">
                                                            ${InvoiceData?.data?.pricingDetails?.grandTotal}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>

                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>

    )
}

export default PurchaseInvoice