import React, { useEffect } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { Outlet } from 'react-router-dom';
import customJs from '../assets/js/custom'
import initializeCustomScripts from '../assets/js/custom';
import Aos from 'aos';


function AuthLayout() {
    useEffect(() => {
        Aos.init()
    }, []);

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default AuthLayout