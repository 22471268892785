import React from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'

function Help() {
    return (
        <>
            <InnerPageHeader title="Help"/>
        </>
    )
}

export default Help