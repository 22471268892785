import React, { useEffect } from 'react'
import { useAuth } from '../../../context/AuthContext'
import InnerPageHeader from '../../../common/InnerPageHeader'
import CourseApis from '../../../queries/course'
import { useNavigate } from 'react-router-dom'
import emptyCartImg from '../../../assets/images/emptycart.png'
import toast from 'react-hot-toast'
import { useSubmitHandler } from '../../../context/PaymentSubmitContext'
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next'

function Cart() {
  const { isAuthenticated } = useAuth()
  const { submitRef, setpaymentLoading } = useSubmitHandler()
  const { data: cartItems, refetch } = CourseApis.GetCartItems()
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const dltCartItems = CourseApis.DltCartItems()
  const purchase = CourseApis.PurchaseCourse()
  const { t } = useTranslation('purchase')
  const handleDlt = async (id) => {
    const response = await dltCartItems.mutateAsync(id)
    if (response.status) {
      await refetch()
    }
  }


  const checkoutHandler = () => {
    if (cartItems?.data?.products.length == 0) return;
    submitRef.current = async (paymentMethod, paymentResponse) => {
      try {
        if (paymentMethod) {
          setpaymentLoading(true)
          const response = await purchase.mutateAsync({ paymentMethod, paymentResponse });

          if (response.status) {
            setpaymentLoading(false)
            toast.success(t("Purchase successful"));
            queryClient.invalidateQueries('get-logged-user-details')
            navigate('/my-account');

          } else {
            setpaymentLoading(false)
            toast.error(t("Purchase failed"));
            navigate('/cart');
          }
        }
      } catch (error) {
        toast.error(t("Purchase failed"));
      }
    };


    navigate('/payment');
  };

  return (
    <>
      <InnerPageHeader
        title="Cart"
        subtitile='Courses'
        titlePath='cart'
        subTitlePath='courses'
        t={t}
      />
      <section className="cartContantArea">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="cart_top_row">
                <span>{t("Total")} {cartItems?.data?.details?.totalCount} {t("Items")}</span>
                {
                  cartItems?.data?.products.length > 0 &&
                  <a href="#" className="clearCartBtn" onClick={() => handleDlt('')}>{t("Clear All")}</a>
                }

              </div>
              <div className="cart_cnt">
                {
                  cartItems?.data?.products.length > 0 ?
                    cartItems?.data?.products?.map((item, index) => {
                      return (
                        <div className="cart_cnt_row">
                          <div className="cart_cnt_row_img">
                            <img src={item.image} alt="Course" />
                          </div>
                          <div className="cart_cnt_row_cnt">
                            <div className="cart_cnt_row_cnt_left">
                              <h4>{item.title}</h4>
                              <span>{t("Tutor")}: {item.tutor}</span>
                              <span>{item.rating}</span>
                              <span>{item.hours.hours} {t("hours")} | {item.count.task} {t("Lectures")} | {item.difficulty}</span>
                            </div>
                            <div className="cart_cnt_row_cnt_right">
                              <span>
                                {t("Total")}
                                <strong>${item.price}</strong>
                              </span>
                              <a href="#" className="cartSingleDelete" onClick={() => handleDlt(item.id)}>
                                <i className="fa fa-trash"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    :
                    <div>
                      <center>
                        <img src={emptyCartImg} className='w-25' /><br />
                        <button className='continue-shopping' onClick={() => navigate('/courses')}>{t("Continue shopping")}</button>
                      </center>


                    </div>

                }


              </div>
            </div>
            <div className="col-md-4">
              <div className="cart_right_checkout">
                <h4>{t("Checkout")}</h4>
                <div className="cart_right_checkout_dtl">
                  <div className="cart_right_checkout_dtl_cnt">
                    {
                      cartItems?.data?.products?.map((item, index) => {
                        return (
                          <div className="cart_right_checkout_dtl_row">
                            <span>{item.title}</span>
                            <span>${item.price}</span>
                          </div>
                        )
                      })
                    }

                    {/* <div className="cart_right_checkout_dtl_row">
                      <span>Course Name</span>
                      <span>$500</span>
                    </div> */}
                  </div>
                  <div className="cart_right_checkout_btm">
                    <div className="cart_right_checkout_dtl_row">
                      <span>{t("Total")}</span>
                      <span>${cartItems?.data?.details?.totalPrice}</span>
                    </div>
                    <div className="cart_right_checkout_dtl_row">
                      <span>{t("Tax")}</span>
                      <span>${cartItems?.data?.details?.tax}</span>
                    </div>
                    <div className="cart_right_checkout_dtl_row checkout_payble">
                      <span>{t('Total')}</span>
                      <span>${cartItems?.data?.details?.totalPrice}</span>
                    </div>
                  </div>
                </div>
                <a href="#" className="checkoutBtnCart"
                  onClick={checkoutHandler}
                >{t('Checkout')}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default Cart