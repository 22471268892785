import api from "../../api/axios.instance"

export const login = async (data) => {
    try {
        const response = await api.post('login', data)
        return response
    } catch (error) {
        return error
    }
}

export const logout = async () => {
    try {
        const response = await api.post('common/logout')
        return response
    } catch (error) {
        return error
    }
}
export const getHome = async () => {
    try {
        const response = await api.get('home')
        return response
    } catch (error) {
        return error
    }
}
export const getCompanydetails = async () => {
    try {
        const response = await api.get('company-details')
        return response
    } catch (error) {
        return error
    }
}
export const contact = async (data) => {
    try {
        const response = await api.post('contact-us', data)
        return response
    } catch (error) {
        return error
    }
}
export const forgotPassword = async (data) => {
    try {
        const response = await api.post('forgot-password', data)
        return response
    } catch (error) {
        return error
    }
}
export const resetPassword = async (data) => {
    try {
        const response = await api.post('change-password', data)
        return response
    } catch (error) {
        return error
    }
}
export const googleSignUp=async(data)=>{
    try {
        const response = await api.post('google-auth', data)
        return response
    } catch (error) {
        return error
    }
}
export const twoFaVerify=async(data)=>{
    try {
        const response = await api.post('two-fa-login', data)
        return response 
    } catch (error) {
        return error
    }
}