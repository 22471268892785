import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import resetImg from '../../assets/images/My password-pana.png'
import { Icon } from '@iconify/react'
import { useParams, useNavigate } from 'react-router-dom'
import AuthApis from '../../queries/auth'
import toast from 'react-hot-toast'

function Resetpassword() {
  const { register, handleSubmit, formState: { errors }, setError, reset } = useForm()
  const [eyeOPen, seteyeOPen] = useState(false)
  const { token } = useParams()
  const navigate = useNavigate()
  const resetPassword = AuthApis.ResetPassword()

  const submitHandler = async (data) => {
    data.token = token
    const response = await resetPassword.mutateAsync(data)
    if (response.status) {
      reset("")
      navigate('/login')
      toast.success('Password reset successfull')
    }else{
      toast.error('Password reset failed')
    }
  }
  return (
    <section className="login_section" data-aos="fade-right">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="login_box">
              <div className="login_box_left_sc">
                <h3>
                  {/* <span>Forgot password,</span><br /> */}
                  Reset Password
                </h3>
                <form onSubmit={handleSubmit(submitHandler)} className='reset-pswd-form'>
                  <div className="form-floating mb-3">
                    <input type={`${eyeOPen ? 'text' : 'password'}`}  {...register("password", {
                      required: "password is required",
                      minLength: {
                        value: 8,
                        message:'Minimum 8 characters'
                      }
                    })}
                      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      id="floatingInputEmail" placeholder="name@example.com" />
                    <label htmlFor="floatingInputEmail">Password</label>
                    <div class="invalid-feedback">
                      {errors?.password?.message}
                    </div>

                    <span className='pswd-eye' onClick={() => seteyeOPen(!eyeOPen)}>
                      {
                        eyeOPen ?
                          <Icon icon="oi:eye" width="20" height="20" />
                          : <Icon icon="ooui:eye-closed" width="20" height="20" />

                      }

                    </span>

                  </div>


                  <button className="btn btn-primary" type="submit" disabled={resetPassword.isPending?true:false}>Submit</button>
                </form>

              </div>
              <div className="login_box_right_sec text-center">
                <h3>
                  Enter Your <br />
                  New Password
                </h3>
                <div className="loginRegisterImage p-5">
                  <img className="img-fluid" src={resetImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Resetpassword