import React from 'react';
import { AuthProvider } from './AuthContext';
import { SubmitHandlerProvider } from './PaymentSubmitContext';

const RootContextProvider = ({ children }) => {
  return (
    <AuthProvider>
      <SubmitHandlerProvider>
        {children}
      </SubmitHandlerProvider>
    </AuthProvider>
  );
};

export default RootContextProvider;