import api from "../../api/axios.instance"

export const getKycCategories = async () => {
    try {
        const response = await api.get('common/kyc-category')
        return response
    } catch (error) {
        return error
    }
}
export const uploadKyc = async (data) => {
    try {
        const response = await api.post('common/kyc', data)
        return response
    } catch (error) {
        return error
    }
}
export const getUploadedKycs = async (category) => {

    try {
        const response = await api.get('common/kyc', { params: { category } })
        return response
    } catch (error) {
        return error
    }
}
export const dltKycFile = async (data) => {
    try {
        const response = await api.delete('common/kyc', {data})
        return response
    } catch (error) {
        return error
    }
}

