import React from 'react'
import CommonApis from '../../../queries/common'
import moment from 'moment'
import InnerPageHeader from '../../../common/InnerPageHeader'
import { Icon } from '@iconify/react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import noDataImg from '../../../assets/images/No data-cuate.png'
import { useTranslation } from 'react-i18next';
function Notifications() {
    const { t } = useTranslation('profile')
    const { data: notificationData, refetch } = CommonApis.GetNotifications()
    const markReadallNotifications = CommonApis.ReadAllNotificatio()
    const markAllnotiRead = async () => {
        const response = await markReadallNotifications.mutateAsync()
        if (response.status) {
            await refetch()
        }
    }
    return (
        <>
            <InnerPageHeader
                title="Notifications"
                subtitile="My Account"
                titlePath="notifications"
                subTitlePath="my-account"
                t={t}
            />
            <section className="home_category_section">
                <div className="container">
                    <div className="main_heading_txt">
                        <div className="row align-items-center">
                            <div className="col-md-12" data-aos="fade-up">
                                {/* <h2>
                                    Stay Updated With<br />
                                    <strong>Recent Notifications...</strong>
                                </h2> */}


                                <ul class="list-group list-group-flush ">
                                    {
                                        notificationData?.data?.notifications.length > 0 &&
                                        <div className='mark-all-read-noti'>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id="button-tooltip">
                                                        {t('Mark all read')}
                                                    </Tooltip>
                                                }
                                            >
                                                <Icon icon="solar:check-read-linear" width="24" height="24" onClick={markAllnotiRead} />
                                            </OverlayTrigger>

                                        </div>
                                    }

                                    {
                                        notificationData?.data?.notifications.length > 0 ?
                                            notificationData?.data?.notifications.map((item, index) => {
                                                return (
                                                    <li class="list-group-item" >
                                                        <div className='notification-items' data-aos="fade-down">
                                                            <span className='noti-counter'>{index + 1}</span>
                                                            <div className='noti-content'>
                                                                <span className='noti-heading'>{item.type}</span>
                                                                <blockquote className='noti-quote'>
                                                                    {item.message}
                                                                    {/* <cite>- {item.message}</cite> */}
                                                                </blockquote>
                                                            </div>
                                                            <span className='noti-date'>{moment(item.createdAt).format('DD-MM-YYYY hh:mm:ss')}</span>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            :
                                            <center>

                                                <img src={noDataImg} className='w-50' />
                                                <span>{t("No data found")}</span>
                                            </center>

                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>


    )
}

export default Notifications