import React from 'react'
import bannerImg from '../../assets/images/banner-image.png'
import { Link } from 'react-router-dom'
function Banner({t}) {
    return (
        <section className="banner_section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h1>
                            {t("Learn Your")} <strong>{t("Desired Skill")}</strong>
                            <strong>{t("From The")} <span>{t("Experts")}</span></strong>
                        </h1>
                        <p>
                            {t("Discover a world of knowledge and opportunities with our online education platform. Pursue a new career")}.
                        </p>
                        <div className="d-flex gap-3">
                            <Link to='courses' className="btn btn-primary">
                                {t("View All Course")} <i className="fa-solid fa-arrow-right"></i>
                            </Link>
                            <a href="#" className="btn btn-secondary">{t("Learn More")}</a>
                        </div>
                    </div>
                    <div className="col-md-6 banner-image">
                        <img src={bannerImg} alt="" />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Banner