import moment from 'moment';
import React from 'react'
import profilePic from '../../../assets/images/profilepic.png'

function Reviews({ courseDetails,t }) {

    const stars = [1, 2, 3, 4, 5];
    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="product-reviews">
                        <div className="product-reviews__info reviews-info">
                            <h2 className="reviews-info__title headline mb-0"><i className="fa-solid fa-star star_rating"></i>
                                {courseDetails?.data?.rating?.averageRating}</h2>
                            <h6 className="">{courseDetails?.data?.rating?.totalCount} {t("reviews")}</h6>
                            <fieldset className="rating">
                                <div className="rating__group">

                                    {stars.map((star, index) => (
                                        <input
                                            key={index}
                                            type="radio"
                                            className="rating__star"
                                            name="product-rating"
                                            value={star}
                                            aria-label={`${star} star`}
                                            checked={Math.ceil(courseDetails?.data?.rating?.averageRating) === star}
                                            readOnly
                                        />
                                    ))}
                                </div>
                            </fieldset>
                            {/* <span className="reviews-info__caption">9 out of 12 (75%)</span> */}
                        </div>
                        <div className="product-reviews__bar reviews-bar">
                            <ul className="list-reset reviews-bar__list">
                                {
                                    courseDetails?.data?.rating?.perc?.slice().reverse().map((item, index) => {
                                        return (
                                            <li className="reviews-bar__item">
                                                <div className="progress-bar">
                                                    <span className="progress-bar__star">{item.rating}</span>
                                                    <div className="progress-bar__outter-line" data-rating={item.count}>
                                                        <span className="progress-bar__inner-line progress-bar__inner-line--excellent"
                                                            style={{ width: `${item.percentage}%` }}></span>
                                                    </div>
                                                    <span id="value" className="progress-bar__quantity">{item.count}</span>
                                                </div>
                                            </li>
                                        )
                                    })
                                }


                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    {
                        courseDetails?.data?.course?.review?.map((item, index) => {
                            return (
                                <div className="reviewViewRow">
                                    <div className="reviewViewImg">
                                        <img  className="reviewViewImg"src={item?.by?.user_detail?.profilePhoto ?? profilePic} alt='noimage' />
                                    </div>
                                    <div className="reviewViewCnt">
                                        <h6>{item.by.username}</h6>
                                        <p>{item.description} </p>
                                        <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
        </>
    )
}

export default Reviews