import React from 'react'
import logo from '../assets/images/logo.png'
import { useAuth } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import AuthApis from '../queries/auth'
function Footer() {
    const { isAuthenticated } = useAuth()
    const navigate = useNavigate()
    const { data: CompanyDetails } = AuthApis.GetcompanyDetails()
    console.log("====CompanyDetails====", CompanyDetails)
    return (
        <footer className="footer_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="footer_box">
                            <div className="footer_box_head"><img src={logo} alt="" /></div>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                Lorem Ipsum has been the industry's standard dummy text.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer_box">
                            <div className="footer_box_head">Resources</div>
                            <ul>
                                <li>
                                    {
                                        isAuthenticated ?
                                            <Link to='/my-account'>Home</Link>
                                            :
                                            <Link to='/'>Home</Link>
                                    }

                                </li>
                                <li><Link to='/categories'>Categories</Link></li>
                                <li><Link to='/courses'>Courses</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer_box">
                            <div className="footer_box_head">Quick links</div>
                            <ul>
                                {
                                    isAuthenticated ?
                                        <li><Link to="/my-learnings">My Learnings</Link></li>
                                        :
                                        <>
                                            <li><Link to="/login">Login</Link></li>
                                            <li><Link to="/register">Register</Link></li>
                                        </>

                                }


                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer_box">
                            <div className="footer_box_head">Connect with Us</div>
                            <ul>
                                <li><span><i className="fa-brands fa-facebook"></i></span> <a href={CompanyDetails?.data?.facebook}>Facebook</a></li>
                                <li><span><i className="fa-brands fa-x-twitter"></i></span> <a href={CompanyDetails?.data?.twitter}>Twitter</a></li>
                                <li><span><i className="fa-brands fa-linkedin-in"></i></span> <a href={CompanyDetails?.data?.linkedin}>LInkedIn</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_copyright">
                <div className="container">
                    <div className="footer_copyright_cnt">
                        <span>Copyright @ LMS</span>
                        <span>
                            <a href="#">Terms and Condition</a>
                            <a href="#">Privacy Policy</a>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer