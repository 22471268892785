import React from "react";
import {Icon} from '@iconify/react'


function CardPagination({ page, totalEntry, length, pageChangehandler }) {
    const totalPagecount = Math.ceil(totalEntry / length);

    const getPageNumbers = () => {
        const pageNumbers = [];
        const delta = 2; 
        const rangeStart = Math.max(2, page - delta); 
        const rangeEnd = Math.min(totalPagecount - 1, page + delta);
        pageNumbers.push(1);

        if (rangeStart > 2) {
            pageNumbers.push("...");
        }

        for (let i = rangeStart; i <= rangeEnd; i++) {
            pageNumbers.push(i);
        }

        if (rangeEnd < totalPagecount - 1) {
            pageNumbers.push("...");
        }

        if (totalPagecount > 1) {
            pageNumbers.push(totalPagecount);
        }

        return pageNumbers;
    };

    const pages = getPageNumbers();

    return (
        <div className="pagination">
            <button 
                className="arrow" 
                id="prevPage" 
                disabled={page === 1} 
                onClick={() => pageChangehandler(page - 1)}
            >
                {/* ← <span className="nav-text">PREV</span> */}
                {/* <Icon icon="carbon:previous-filled" width="24" height="24" /> */}
                <Icon icon="fluent:arrow-previous-12-filled" width="24" height="24" />
            </button>
            <div className="pages">
                {
                    pages.map((pageIndex, idx) => 
                        typeof pageIndex === "number" ? (
                            <div
                                key={idx} 
                                className={`page-number ${pageIndex === page ? 'page-active' : ''}`}
                                onClick={() => pageChangehandler(pageIndex)}
                            >
                                {pageIndex}
                            </div>
                        ) : (
                            <span key={idx} className="ellipsis">...</span>
                        )
                    )
                }
            </div>
            <button 
                className="arrow" 
                id="nextPage" 
                disabled={page === totalPagecount} 
                onClick={() => pageChangehandler(page + 1)}
            >
                {/* <span className="nav-text">NEXT</span> → */}
                {/* <Icon icon="carbon:next-filled" width="24" height="24" /> */}
                <Icon icon="fluent:arrow-next-12-filled" width="24" height="24" />
              
            </button>
        </div>
    );
}

export default CardPagination;
